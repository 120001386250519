<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="4.5" r="3.5" stroke="#A7A8AA" stroke-width="2" fill="none"/>
    <path
      d="M8 19V11C4.9375 11 1 15.2667 1 19H8Z"
      stroke="#A7A8AA"
      stroke-width="2"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M18.9492 14.4985L18.1548 15.4375C18.1657 15.2732 18.1362 15.1057 18.0642 14.9504C17.9737 14.7555 17.8255 14.5993 17.6453 14.4985C17.8255 14.3976 17.9737 14.2415 18.0642 14.0466C18.1362 13.8912 18.1657 13.7237 18.1548 13.5594L18.9492 14.4985ZM17.6347 13.2312C17.6347 13.2312 17.6352 13.2306 17.6363 13.2293C17.6353 13.2306 17.6348 13.2312 17.6347 13.2312ZM17.6387 15.7772C17.6387 15.7772 17.6392 15.7778 17.6401 15.779C17.6391 15.7778 17.6386 15.7772 17.6387 15.7772Z"
      stroke="#A7A8AA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M15 10H13C12.4477 10 12 10.4477 12 11V18C12 18.5523 12.4477 19 13 19H15"
      stroke="#A7A8AA"
      stroke-width="2"
      stroke-linecap="round"
      fill="none"
    />
  </svg>
</template>
  
  <script>
import { Vue } from "vue-class-component";

export default class IconDetailSender extends Vue {}
</script>
    